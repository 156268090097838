import { HumanVerification } from "@/components/auth/HumanVerification/HumanVerification";
import { handleApiResponse } from "@/utils/common/api/handleApiResponse";
import { useValidateMagicLink } from "@/utils/hooks/api/auth/useValidateMagicLink";
import { Center, Stack, Text } from "@mantine/core";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { routesList } from "../../routesList";
import { storeToken } from "../../utils/auth/storeToken";

export default observer(function MagicLinkView() {
	const navigate = useNavigate();
	const [URLSearchParams] = useSearchParams();

	const code = URLSearchParams.get("code");

	const { trigger: validateCode } = useValidateMagicLink();

	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [turnstileToken, setTurnstileToken] = useState(null);

	useEffect(() => {
		if (turnstileToken) {
			checkCode({ code, token: turnstileToken });
		}
	}, [turnstileToken]);

	const checkCode = async ({ code, token }) => {
		if (!code) return;

		const apiResponse = await validateCode({
			query: {
				code,
				token,
			},
		});

		handleApiResponse({
			id: "login",
			apiResponse: apiResponse,
			successTitle: "Sesión iniciada con éxito",
			onSuccess: async () => {
				await storeToken(apiResponse.response.token);
				navigate(routesList.mementos.path);
			},
		});

		if (apiResponse.isError) {
			setIsError(true);
			setIsLoading(false);
		}
	};

	return (
		<Center h="100vh">
			{isError && <Text>Código inválido</Text>}
			{isLoading && (
				<Stack>
					<Text ta="center">Validando...</Text>
					<HumanVerification onGetToken={setTurnstileToken} />
				</Stack>
			)}
		</Center>
	);
});
