import { userStore } from "@/mobx/rootStore";
import { routesList } from "@/routesList";
import { Button, Stack, Title } from "@mantine/core";
import { IconBox } from "@tabler/icons-react";
import { IconMoneybag } from "@tabler/icons-react";
import { IconBrain } from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";

const APPS = [
	{
		label: "Mementos",
		icon: <IconBrain />,
		appName: "mementos",
		route: routesList.mementos.path,
	},
	{
		label: "Items",
		icon: <IconBox />,
		appName: "eidos",
		route: routesList.eidos.path,
	},
	{
		label: "Economos",
		icon: <IconMoneybag />,
		appName: "economos",
		route: routesList.economosMovements.path,
	},
];

export default function AppSelector({ closeMobile, withTitle = true }) {
	const location = useLocation();
	const navigate = useNavigate();

	const currentApp = (location.pathname ?? "").split("/")[1]; // "/mementos/asd" -> "mementos"

	const onAppClick = (app) => {
		userStore.lastApp = app.appName;

		navigate(app.route);
		closeMobile();
	};

	return (
		<Stack gap="sm">
			{withTitle && <Title order={4}>Aplicaciones</Title>}

			{APPS.map((_app) => {
				const isCurrentApp = _app.appName === currentApp;
				const isExactPath = location.pathname === _app.route;

				return (
					<Button
						key={_app.appName}
						variant={isCurrentApp ? "light" : "subtle"}
						onClick={isExactPath ? undefined : () => onAppClick(_app)}
						leftSection={_app.icon}
					>
						{_app.label}
					</Button>
				);
			})}
		</Stack>
	);
}
