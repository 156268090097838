import { lazy } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import PrivateLayout from "./Layouts/private/PrivateLayout";
import MagicLink from "./pages/MagicLink/MagicLink";
import { routesList } from "./routesList";

const HomeView = lazy(() => import("./pages/Home/HomeView"));

const LoginView = lazy(() => import("./pages/Login/LoginView"));
const RegisterView = lazy(() => import("./pages/Register/RegisterView"));

const CrewInvitation = lazy(() => import("./pages/CrewInvitation/CrewInvitationView"));
const EidosView = lazy(() => import("./pages/Eidos/EidosView"));
const MementosView = lazy(() => import("./pages/Mementos/MementosView"));

const EconomosHomeView = lazy(() => import("./pages/Economos/Home/EconomosHomeView"));
const EconomosMovementsView = lazy(
	() => import("./pages/Economos/Movements/EconomosMovementsView"),
);
const EconomosSearchView = lazy(() => import("./pages/Economos/Search/EconomosSearchView"));

const OnboardingGroup = lazy(() => import("./pages/Onboarding/GroupView"));

const HealbotView = lazy(() => import("./pages/Healbot/HealbotView"));

export function RoutesGroup() {
	return (
		<Routes>
			{/* ********************************** */}
			{/* Public routes */}
			{/* ********************************** */}
			<Route
			// element={<WebHeader><Outlet /></WebHeader>}
			>
				<Route path={routesList.home.path} exact element={<HomeView />} />

				<Route path={routesList.login.path} exact element={<LoginView />} />
				<Route path={routesList.magicLink.path} exact element={<MagicLink />} />

				<Route path={routesList.register.path} exact element={<RegisterView />} />
			</Route>

			{/* ********************************** */}
			{/* Layoutless routes */}
			{/* ********************************** */}

			<Route path={routesList.crewInvitation.path} exact element={<CrewInvitation />} />

			<Route path={routesList.healbot.path} exact element={<HealbotView />} />

			{/* ********************************** */}
			{/* Private routes */}
			{/* ********************************** */}

			<Route
				element={
					<PrivateLayout>
						<Outlet />
					</PrivateLayout>
				}
			>
				<Route path={routesList.eidos.path} exact element={<EidosView />} />
				<Route path={routesList.mementos.path} exact element={<MementosView />} />

				<Route path={routesList.economos.path} exact element={<EconomosHomeView />} />
				<Route
					path={routesList.economosMovements.path}
					exact
					element={<EconomosMovementsView />}
				/>
				<Route
					path={routesList.economosSearch.path}
					exact
					element={<EconomosSearchView />}
				/>

				<Route path={routesList.onboardingGroup.path} exact element={<OnboardingGroup />} />
			</Route>
		</Routes>
	);
}
