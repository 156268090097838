import { swrMutator } from "@/utils/swr/mutator";
import useSWRMutation from "swr/mutation";

export function useValidateMagicLink() {
	const { trigger, isMutating, data, error } = useSWRMutation(
		"/auth/magicLink",
		(url, options) => {
			options.arg.method = "get";

			const query = new URLSearchParams();
			query.append("code", options.arg?.query?.code);
			query.append("token", options.arg?.query?.token);

			url = `${url}?${query.toString()}`;

			return swrMutator(url, options);
		},
	);

	return {
		trigger,
		isMutating,
		data,
		error,
	};
}
