export default {
	GET_WITH_FILTER: ({ listId, year, month }) =>
		`/economos/movement/filter?listId=${listId}&year=${year}&month=${month}`,
	GET_RECOMMENDATION: ({ listId, date, amount, name, tags }) => {
		const searchParams = new URLSearchParams();
		if (listId) searchParams.append("listId", listId);
		if (date) searchParams.append("date", date);
		if (amount) searchParams.append("amount", amount);
		if (name) searchParams.append("name", name);
		if (tags) searchParams.append("tags", tags);

		return `/economos/movement/recommendation?${searchParams.toString()}`;
	},
	CREATE: () => "/economos/movement",
	UPDATE: () => "/economos/movement",
	DELETE: (id) => `/economos/movement/${id}`,
};
