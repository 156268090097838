import auth from "./entities/auth.js";
import crew from "./entities/crew.js";
import houseTask from "./entities/houseTask.js";
import enemy from "./entities/enemy.js";
import houseItem from "./entities/houseItem.js";
import houseItemCategory from "./entities/houseItemCategory.js";
import houseItemList from "./entities/houseItemList.js";
import economosList from "./entities/economosList.js";
import economosMovement from "./entities/economosMovement.js";

export const endpoints = {
	auth,
	crew,
	houseTask,
	enemy,
	houseItem,
	houseItemCategory,
	houseItemList,
	economosList,
	economosMovement,
};
