import Logo from "@/components/common/Logo";
import { crewStore, uiStore } from "@/mobx/rootStore";
import { AppShell, Burger, Divider, Group, Stack, Text, rem } from "@mantine/core";
import { useHeadroom } from "@mantine/hooks";
import { useDrag } from "@use-gesture/react";
import { observer } from "mobx-react-lite";
import { version } from "../../../package.json";
import AppSelector from "./partials/AppSelector";
import CrewSelector from "./partials/CrewSelector";
import UserInfo from "./partials/UserInfo";

export default observer(function PrivateLayout({ children }) {
	const pinned = useHeadroom({ fixedAt: 120 });

	const mobileOpened = uiStore.getMobileNavbarState();
	const desktopOpened = uiStore.getDesktopNavbarState();

	const bind = useDrag(
		({ swipe, tap }) => {
			if (tap) {
				return;
			}

			const swipeX = swipe[0]; // position will either be -1, 0 or 1

			if (swipeX === 0) {
				return;
			}
			if (swipeX === -1) {
				uiStore.closeMobileNavbar();
			}
			if (swipeX === 1) {
				uiStore.openMobileNavbar();
			}
		},
		{
			filterTaps: true,
			axis: "x",
			swipe: {
				distance: 20,
				velocity: 1,
				duration: 1500,
			},
		},
	);

	const hasCrews = crewStore.hasCrews();

	return (
		<AppShell
			padding="md"
			header={{
				height: 60,
				collapsed: !mobileOpened && !pinned,
				offset: mobileOpened || desktopOpened,
			}}
			navbar={{
				width: 300,
				breakpoint: "sm",
				collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
			}}
			style={{
				touchAction: "pan-y",
			}}
			{...bind()}
			data-swipe-action="openMenu"
		>
			<AppShell.Header>
				<Group h="100%" px="md">
					<Burger
						opened={mobileOpened}
						onClick={uiStore.toggleMobileNavbar}
						hiddenFrom="sm"
						size="sm"
					/>
					<Burger
						opened={desktopOpened}
						onClick={uiStore.toggleDesktopNavbar}
						visibleFrom="sm"
						size="sm"
					/>

					<Group gap="sm">
						<Logo />

						<Text size="lg" fw="bold">
							Agora
						</Text>
					</Group>

					<Text size="sm" c="dimmed">
						{version}
					</Text>
				</Group>
			</AppShell.Header>

			<AppShell.Navbar p="md">
				<Stack gap="xl" justify="space-between" h={"100%"} data-swipe-action="closeMenu">
					<Stack>
						<CrewSelector />
						<Divider />
						{hasCrews && <AppSelector closeMobile={uiStore.closeMobileNavbar} />}
					</Stack>

					<Stack>
						<Divider />
						<UserInfo />
					</Stack>
				</Stack>
			</AppShell.Navbar>

			<AppShell.Main pt={`calc(${rem(60)} + var(--mantine-spacing-md))`}>
				{children}
			</AppShell.Main>
		</AppShell>
	);
});
