import { Stack, Text } from "@mantine/core";
import Turnstile from "react-turnstile";

const TURNSTILE_SITE_KEY = import.meta.env.VITE_CLOUDFLARE_TURNSTILE_SITE_KEY;

export function HumanVerification({ onGetToken = () => {} }) {
	return (
		<Stack gap="0">
			<Text size="sm">Verificación humana</Text>
			<Turnstile
				sitekey={TURNSTILE_SITE_KEY}
				onVerify={(token) => {
					onGetToken(token);
				}}
				refreshExpired="auto"
			/>
		</Stack>
	);
}
