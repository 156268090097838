import dayjs from "dayjs";
import { makeAutoObservable } from "mobx";
import construct from "../utils/construct";
import persist from "../utils/persist";

// eslint-disable-next-line
let _rootStore;

const defaultProperties = {
	/** @type {string} */
	currentListId: null,
	currentDate: dayjs().startOf("month").toDate(),
};

export default class EconomosStore {
	constructor(rootStore) {
		construct(this, defaultProperties);
		_rootStore = rootStore;

		makeAutoObservable(this);
		persist(this, "economosStore");
	}

	reset() {
		construct(this, defaultProperties);
	}

	getSelectedListId() {
		return this.currentListId;
	}

	getIsSelectedtListEmpty() {
		return this.getSelectedListId() === null;
	}

	/**
	 * @param {string} listId
	 */
	setSelectedListId(listId) {
		if (!listId) return;
		if (typeof listId !== "string") return;

		this.currentListId = listId;
	}

	/**
	 * @returns {Date}
	 */
	getCurrentDate() {
		return this.currentDate ?? this.resetCurrentDate();
	}

	getCurrentMonthAndYear() {
		const currentDate = dayjs(this.getCurrentDate());

		return {
			month: currentDate.month() + 1,
			year: currentDate.year(),
		};
	}

	isSameMonth({ date, useYear = false }) {
		const _date = dayjs(this.getCurrentDate());
		const _targetDate = dayjs(date);

		const isSameYear = useYear ? _date.isSame(_targetDate, "year") : true;
		const isSameMonth = _date.isSame(_targetDate, "month");

		return isSameYear && isSameMonth;
	}

	changeCurrentDate({ month = 1 }) {
		const next = dayjs(this.currentDate).startOf("month").add(month, "month");
		this.currentDate = next.toDate();
	}

	setCurrentDate({ date }) {
		const nextDate = dayjs(date).startOf("month").toDate();
		this.currentDate = nextDate;
	}

	/**
	 * @returns {Date} The new current date.
	 */
	resetCurrentDate() {
		this.currentDate = dayjs().startOf("month").toDate();
		return this.currentDate;
	}
}
