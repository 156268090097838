/**
 * Modifies the options object by setting the method to be used for the request
 * @param {Object} params
 * @param {{}} params.options
 * @param {"get" | "post" | "put" | "delete" | "patch"} params.method
 */
export function setMethod({ options = {}, method }) {
	if (!options.arg) options.arg = {};

	options.arg.method = method;
}
