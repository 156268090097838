import { LoadingOverlay, MantineProvider, rem } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import dame from "dame";
import { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import { SWRDevTools } from "swr-devtools";
import "./config/dameConfig";
import "./config/dayjsConfig";
import { userStore } from "./mobx/rootStore";
import { RoutesGroup } from "./routes";
import { swrFetcher } from "./utils/swr/fetcher";

// Custom css
import "./index.css";
import BadgeClasses from "./styles/components/Badge.module.css";

// Third party
import "driver.js/dist/driver.css";

const DEFAULT_FONT_FAMILY =
	"Sarala, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji";

export default function App() {
	useEffect(() => {
		(async () => {
			if (userStore?.token) {
				dame.headers.Authorization = `Bearer ${userStore?.token}`;
				await userStore.init();
			}
		})();
	}, []);

	return (
		<MantineProvider
			defaultColorScheme="dark"
			withGlobalClasses
			/* cssVariablesResolver={(theme) => {
				return {
					variables: {},
				};
			}} */

			// https://github.com/mantinedev/mantine/blob/2f91229da10d30a205ade277161bc8829740bd93/packages/%40mantine/core/src/core/MantineProvider/default-theme.ts
			theme={{
				fontFamily: DEFAULT_FONT_FAMILY,

				scale: 1, // https://mantine.dev/styles/rem/#rem-units-scaling

				primaryColor: "indigo",

				fontSizes: {
					n: rem(6),
					xxxs: rem(8),
					xxs: rem(10),
					xs: rem(12),
					sm: rem(14),
					md: rem(16),
					lg: rem(18),
					xl: rem(20),
					xxl: rem(22),
					xxxl: rem(24),
					g: rem(26),
				},

				spacing: {
					n: rem(2),
					xxxs: rem(4),
					xxs: rem(8),
					xs: rem(10),
					sm: rem(12),
					md: rem(16),
					lg: rem(20),
					xl: rem(32),
					xxl: rem(36),
					xxxl: rem(40),
				},

				breakpoints: {
					xs: "36em",
					sm: "48em",
					md: "62em",
					lg: "75em",
					xl: "88em",
				},

				components: {
					LoadingOverlay: {
						defaultProps: {
							overlayProps: { blur: 4, backgroundOpacity: 0 },
							loaderProps: { type: "bars" },
						},
					},
					Badge: {
						classNames: {
							root: BadgeClasses.root,
						},
					},
				},
			}}
		>
			<Notifications />
			<ModalsProvider>
				<SWRConfig value={{ fetcher: swrFetcher }}>
					<BrowserRouter>
						<Suspense
							fallback={
								<LoadingOverlay
									visible={true}
									overlayProps={{
										radius: "sm",
										blur: 1,
									}}
									zIndex={1000}
								/>
							}
						>
							<SWRDevTools>
								<RoutesGroup />
							</SWRDevTools>
						</Suspense>
					</BrowserRouter>
				</SWRConfig>
			</ModalsProvider>
		</MantineProvider>
	);
}
