import { endpoints } from "@/utils/common/requests/endpoints";
import { swrMutator } from "@/utils/swr/mutator";
import { setMethod } from "@/utils/swr/setMethod";
import useSWRMutation from "swr/mutation";

export function useDeleteCrewMember({ crewId, userId }) {
	const { trigger, isMutating, data, error } = useSWRMutation(
		endpoints.crew.DELETE_MEMBER({ crewId, userId }),
		(url, options) => {
			setMethod({ options, method: "delete" });
			url = endpoints.crew.DELETE_MEMBER({ crewId, userId: options.arg?.userId });

			return swrMutator(url, options);
		},
	);

	return {
		trigger,
		isMutating,
		data,
		error,
	};
}
