export const routesList = {
	home: {
		path: "/",
		name: "Inicio",
	},

	register: {
		path: "/register",
		name: "Registro",
	},
	login: {
		path: "/login",
		name: "Iniciar sesión",
	},
	magicLink: {
		path: "/magicLink",
		name: "Magic link",
	},

	crewInvitation: {
		path: "/crew-invitation",
		name: "Invitación a grupo",
	},

	// Apps
	eidos: {
		path: "/eidos",
		name: "Eidos",
	},

	economos: {
		path: "/economos",
		name: "Inicio",
	},
	economosMovements: {
		path: "/economos/movements",
		name: "Movimientos",
	},
	economosSearch: {
		path: "/economos/search",
		name: "Buscar movimientos",
	},
	economosAnalysis: {
		path: "/economos/analysis",
		name: "Analizar movimientos",
	},

	mementos: {
		path: "/mementos",
		name: "Mementos",
	},

	onboardingGroup: {
		path: "/onboarding/group",
		name: "Grupo",
	},

	healbot: {
		path: "/healbot",
		name: "Heal bot",
	},
};
